import React from 'react';
import profileIcon from '../Assets/Images/icons8-user-16.png';

const DeleteAccount = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1>Delete Account</h1>
            <p style={{textAlign: "start", padding: "1em 3em", paddingBottom: "3em"}}>To delete your account, you can follow these instructions:</p>
            <ol style={{textAlign: "start"}}>
                <li>Open the app on your device.</li>
                <li>Click on <strong><img
                    src={profileIcon}
                    alt="Profile Icon"
                    style={{width: '20px', height: '20px', marginLeft: '8px', verticalAlign: 'middle'}}
                /> </strong> on the navigation bar, then click on <strong>Account Settings</strong>.
                </li>
                <li>Click <strong>Delete Account</strong>.</li>
                <li>Then, click on <strong>Delete My Account</strong>.</li>
                <li>Then you will receive a verification code in your email.</li>
                <li>Submit the verification code and finalize the process.</li>
            </ol>
            <br/>
            <p style={{textAlign: "start"}}><strong>Please be advised that you can recover your account within 30 days.</strong></p>
        </div>
    );
}

export default DeleteAccount;
